import { mq } from "../../theme"
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FunctionalComponentProps } from "../../global";

type SpanSize = 'small' | 'regular' | 'medium' | 'large' | 'xl';

type Props = FunctionalComponentProps & {
  onDark?: boolean;
  block?: boolean;
  center?: boolean;
  emphasize?: boolean;
  uppercase?: boolean;
  size?: SpanSize;
}

const SpanSmall = styled.span(({ theme }: any) => css(mq({
  fontSize: theme.fontSize.textSmall,
  fontWeight: 400,
})))

const SpanRegular = styled.span(({ theme }: any) => css(mq({
  fontSize: theme.fontSize.textRegular,
  fontWeight: 400,
})))

const SpanMedium = styled.span(({ theme }: any) => css(mq({
  fontSize: theme.fontSize.textMedium,
  fontWeight: 400,
})))

const SpanLarge = styled.span(({ theme }: any) => css(mq({
  fontSize: theme.fontSize.textLarge,
  fontWeight: 700,
})))

const SpanXL = styled.span(({ theme }: any) => css(mq({
  fontSize: theme.fontSize.textExtraLarge,
  fontWeight: 700,
})))

const spans: {
  [key: string]: any
} = {
  small: SpanSmall,
  regular: SpanRegular,
  medium: SpanMedium,
  large: SpanLarge,
  xl: SpanXL,
};

const Text = ({ block = false, onDark = false, size = 'regular', uppercase = false, emphasize, center, children, ...rest }: Props) => {
  const Span = spans[size];
  if(!Span) {
    return <span css={{ color: 'red' }}>size prop ({size}) not found in Text.tsx</span>
  }

  return (
    <Span 
      css={
        (theme: any) => mq({
          display: block ? 'block' : 'inline-block',
          color: onDark ? theme.colors.white : theme.colors.black,
          textAlign: center ? 'center' : undefined,
          textTransform: uppercase ? 'uppercase' : undefined,
          fontWeight: emphasize ? 700 : undefined,
        })
      }
      {...rest}
    >
      {children}
    </Span>
  )
}

export {
  Text
}