import styled from '@emotion/styled';
import ArrowRight from "../../images/icons/arrow-right.svg";

export enum EButtonType {
    WHITE,
    BLACKONWHITE,
    GREEN,
}

const StyledButton = styled.button((props: any) => ({
    display: 'block',
    borderRadius: '20px',
    height: 40,
    padding: '4px 16px 4px 16px',
    boxShadow: 'none',
    fontSize: props.theme.fontSize.buttonText,
    fontWeight: 400,
    cursor: 'pointer',
    backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0) 50%, #00000035 50%)',
    backgroundPosition: '-0% 0',
    backgroundSize: '210% auto',
    transition: 'background-position 0.5s ease-out',
    ':hover': {
      backgroundPosition: '-95% 0'
    }
}))

const BlackOnWhiteBorderedButton =  styled(StyledButton)((props: any ) => ({
    backgroundColor: props.theme.colors.white,
    border: "2px solid black"
}))

const WhiteButton = styled(StyledButton)((props: any ) => ({
    backgroundColor: props.theme.colors.white,
    border: 'none'  
}))

const GreenButton = styled(StyledButton)((props: any ) => ({
    backgroundColor: props.theme.colors.primary,
    border: 'none',
    color: props.theme.colors.white,  
}))


type ButtonProps = {
    className?: string;
    children: any;
    callToAction?: boolean;
    callToActionIcon?: any;
    onClick?: () => void;
    type?: EButtonType;
}

const Button = ({ type, children, callToAction, callToActionIcon, ...rest }: ButtonProps) => {
    let ButtonComponent = BlackOnWhiteBorderedButton;
    switch(type) {
      case EButtonType.WHITE: {
        ButtonComponent = WhiteButton;
        break;
      }
      case EButtonType.GREEN: {
        ButtonComponent = GreenButton;
        break;
      }
    }
    return (
      <ButtonComponent
        css={{
          fontSize: 14,
          height: 30,
        }}
        {...rest}
      >
        {callToAction && (
          <div
            css={{
              display: 'inline-block',
              position: 'relative',
              height: 16,
              width: 24,
              '> *': {
                position: 'absolute',
                top: 4, 
                left: 0,
                height: 14,
              }
            }}
          >
            {
              callToActionIcon ? callToActionIcon : <ArrowRight /> 
            }
          </div>
        )}
        <div
          css={{
            display: 'inline-block',
            lineHeight: '24px',
            marginTop: -2,
          }}
        >
          {children}
        </div>
      </ButtonComponent>
    );
  }
  
  export default Button;